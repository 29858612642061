<template>
  <v-row>
    <v-col>
      <v-container>
        <v-card rounded="lg" dark>
          <v-card-title class="brown accent-1 white--text">
            <h3>Manage Products</h3>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12" lg="6" xl="6">
                  <v-card color="grey darken-4">
                    <v-card-text>
                      <v-file-input
                        label="Foto Produk"
                        prepend-icon="mdi-camera"
                        accept="image/*"
                        color="light-blue accent-1"
                        outlined
                        rounded
                        show-size
                      ></v-file-input>
                      <v-row>
                        <v-col>
                          <v-card height="500" color="">
                            <v-avatar size="500" tile>
                              <v-img src="@/assets/body_scrub.png"></v-img>
                            </v-avatar>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="12" lg="6" xl="6">
                  <v-card color="grey darken-4">
                    <v-card-text>
                      <v-text-field
                        outlined
                        rounded
                        label="Nama Produk"
                        color="light-blue accent-1"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        rounded
                        label="Deskripsi"
                        color="light-blue accent-1"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        rounded
                        label="Jumlah Terjual"
                        color="light-blue accent-1"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Products",
  data() {
    return {};
  },
};
</script>